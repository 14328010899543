import * as React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination'
import 'swiper/css/effect-cards'

const ClientSwiperCards = () => {
  const data = useStaticQuery(graphql`
    query ClientSwiperCardsQuery {
      webiny {
        listClients(sort: clientProjectDateCompleted_DESC, limit: 20) {
          data {
            id
            clientName
            visibleThumbnail
            clientThumnail
          }
        }
      }
    }
  `)

  const clients = data.webiny.listClients.data

  return (
    <div className="md:p-10">
        <Swiper
          loop={true}
          modules={[EffectCards, Autoplay, Pagination]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          effect={"cards"}
          grabCursor={true}
          pagination={{ clickable: true }}
          className='max-lg'
        >
        {clients.map(client => {
            return (client.visibleThumbnail) ?
              <SwiperSlide loading="lazy" key={client.id} className='rounded-xl'>
                <Link to='/work'>
                  <img src={client.clientThumnail} title={client.clientName} alt={client.clientName} width={800} height={534} className='rounded-xl border-t border-b border-grey-300 shadow-lg ' />
                </Link>
              </SwiperSlide>
              :
              null
        })}
      </Swiper>
      
    </div>
  )
}

export default ClientSwiperCards